// Generated by Framer (ab692b1)

import { addFonts, ComponentViewportProvider, cx, CycleVariantState, getFonts, SmartComponentScopedContainer, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";
import Vimeo from "https://framerusercontent.com/modules/0sWquksFr1YDkaIgrl9Z/VgWe6mCMJOseqaLiMnaC/Vimeo.js";
const VimeoFonts = getFonts(Vimeo);

const serializationHash = "framer-1PSWi"

const variantClassNames = {uY9bIuckd: "framer-v-vc4mpl"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "uY9bIuckd", ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-vc4mpl", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"uY9bIuckd"} ref={refBinding} style={{backgroundColor: "rgb(0, 0, 0)", ...style}}><ComponentViewportProvider ><SmartComponentScopedContainer className={"framer-zam7um-container"} isAuthoredByUser isModuleExternal layoutDependency={layoutDependency} layoutId={"LBWQ4znFb-container"} nodeId={"LBWQ4znFb"} rendersWithMotion scopeId={"OoaorU7vD"}><Vimeo autoplay backgroundColor={"rgba(0, 0, 0, 0)"} borderRadius={0} bottomLeftRadius={0} bottomRightRadius={0} controls height={"100%"} id={"LBWQ4znFb"} isMixedBorderRadius={false} layoutId={"LBWQ4znFb"} loop={false} mute={false} style={{height: "100%", width: "100%"}} topLeftRadius={0} topRightRadius={0} video={"https://vimeo.com/1069130774?share=copy#t=0"} width={"100%"}/></SmartComponentScopedContainer></ComponentViewportProvider></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-1PSWi.framer-1jok7m2, .framer-1PSWi .framer-1jok7m2 { display: block; }", ".framer-1PSWi.framer-vc4mpl { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: 800px; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: 1200px; }", ".framer-1PSWi .framer-zam7um-container { aspect-ratio: 1.7777777777777777 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 675px); position: relative; width: 100%; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-1PSWi.framer-vc4mpl { gap: 0px; } .framer-1PSWi.framer-vc4mpl > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-1PSWi.framer-vc4mpl > :first-child { margin-top: 0px; } .framer-1PSWi.framer-vc4mpl > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 800
 * @framerIntrinsicWidth 1200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerOoaorU7vD: React.ComponentType<Props> = withCSS(Component, css, "framer-1PSWi") as typeof Component;
export default FramerOoaorU7vD;

FramerOoaorU7vD.displayName = "video-overlay-showreel";

FramerOoaorU7vD.defaultProps = {height: 800, width: 1200};

addFonts(FramerOoaorU7vD, [{explicitInter: true, fonts: []}, ...VimeoFonts], {supportsExplicitInterCodegen: true})